import Prismic from '@prismicio/client'

/**
 * Fetch's the sites metadata from the prismic client via a getSingle query of the site_info type
 * and returns as a structure object for nuxt.config.js settings
 * @param {String} endpoint
 * @returns Object { title: String, meta: Array }
 */
export async function fetchSiteInfo(endpoint) {
  const client = Prismic.client(endpoint)
  let siteInfo = await client.getSingle('site_info').then((response) => {
    if (response) {
      return {
        title:
          response.data.meta_title ||
          'Storyboard: The App for Private Podcasts',
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'description',
            name: 'description',
            content: response.data.meta_description,
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: response.data.meta_keywords,
          },
          {
            name: 'robots',
            content: response.data.meta_robots,
          },
          {
            name: 'language',
            content: response.data.meta_lang,
          },
          {
            name: 'theme-color',
            content: '#fffcf2',
          },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: 'trystoryboard.com',
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'website',
          },
        ],
      }
    }
  })
  return siteInfo || {}
}

/**
 * Sets and returns an array for the metadata properties of a page including social media card properties
 * @param {String} description
 * @param {String} keywords
 * @param {Array} socialCards
 * @returns {Array}
 */
export function pageMetaData(description, keywords = '', socialCards = []) {
  let metadata = []

  metadata.push({
    hid: 'description',
    name: 'description',
    content: description,
  })

  if (keywords) {
    metadata.push({
      hid: 'keywords',
      name: 'keywords',
      content: keywords,
    })
  }

  if (socialCards.length > 0) {
    socialCards.forEach((card) => {
      if (card.slice_type === 'general_card') {
        let generalCard = []
        generalCard.push(
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'website',
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: card.primary.title[0]?.text || '',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content:
              card.primary.description?.map((item) => item.text).join(' ') ||
              '',
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: card.primary.image.url || '',
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: card.primary.url || '',
          }
        )

        metadata.push(...generalCard)
      }

      if (card.slice_type === 'twitter_card') {
        let twitterCard = []
        twitterCard.push(
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            hid: 'twitter:creator',
            name: 'twitter:creator',
            content: card.primary.twitter_handle || '',
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: card.primary.title[0]?.text || '',
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content:
              card.primary.description?.map((item) => item.text).join(' ') ||
              '',
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: card.primary.image.url,
          },
          {
            hid: 'twitter:image:alt',
            name: 'twitter:image:alt',
            content: card.primary.image.alt,
          }
        )

        metadata.push(...twitterCard)
      }
    })
  }

  return metadata
}
