
  import SliceZone from 'vue-slicezone'
  import { pageMetaData } from '@/utils/meta'
  import { getfilteredSlices } from '@/utils/prismic'

  export default {
    async asyncData({ $prismic, error }) {
      try {
        const page = await $prismic.api.getByUID('page', 'home')
        if (page && Object.keys(page).length > 0) {
          const allSlices = getfilteredSlices(page.data.slices, 'banner')
          return {
            page,
            // Slices
            slices: allSlices.slices || [],
            banner:
              allSlices.filteredSlices.length > 0
                ? allSlices.filteredSlices
                : null,
            // SEO & Social Media Cards
            socialCards: page.data.slices1,
          }
        } else {
          error({ statusCode: 404, message: 'Page not found' })
        }
      } catch (e) {
        console.error(e)
        // Returns error page
        error({ statusCode: 404, message: 'Page not found' })
      }
    },
    head() {
      if (this.page && Object.keys(this.page).length > 0) {
        return {
          title:
            this.page.data?.meta_title ||
            'Storyboard: The App for Private Podcasts',
          meta: pageMetaData(
            this.page.data?.meta_description || '',
            this.page.data?.meta_keywords || '',
            this.socialCards || []
          ),
        }
      }
    },
    methods: {
      pageMetaData,
    },
    components: {
      SliceZone,
    },
  }
