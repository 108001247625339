// returns two filtered arrays of prismic slices
// sliceList: array of slices without the filtered slice type
// splitSliceList: array of slice with the filtered slice type
// returns { baseSlices: slicesList, filteredSlices: splitSliceList }
export function getfilteredSlices(allSlices, filteredSliceType) {
  if (allSlices) {
    const slicesList = allSlices.filter(
      (slice) => slice.slice_type !== filteredSliceType
    )
    const splitSliceList = allSlices.filter(
      (slice) => slice.slice_type === filteredSliceType
    )
    return { slices: slicesList, filteredSlices: splitSliceList }
  }
  return { slices: [], filteredSlices: [] }
}
